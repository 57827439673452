import {Directive, InjectionToken, Injector, OnDestroy, OnInit} from "@angular/core";
import {ActivatedRoute, ActivatedRouteSnapshot, Params} from "@angular/router";
import {AbstractControl, FormBuilder, FormGroup} from "@angular/forms";
import {Observable} from "rxjs/internal/Observable";
import {map, switchMap, take, takeWhile} from "rxjs/operators";
import {BaseService} from "../services/base.service";
import {BaseComponentCommons, BaseOptionsCommons, handler} from "./base-component-commons";


export interface BaseDetailComponentOptions extends BaseOptionsCommons {
    retrieveOnInit?: boolean;
    retrieveRoute?: string;
    nextRoute?: string;
    nextRouteUpdate?: string;
    noResponse?: boolean;
    confirmHasUnsavedChanges?: boolean;
}

export const EVENT = {
    RETRIEVE: 0,
    SAVE: 1,
    UPDATE: 2,
};

@Directive()
export abstract class BaseComponentDetailDirective<T> extends BaseComponentCommons<T> implements OnInit, OnDestroy {

    public activatedRoute: ActivatedRoute;
    public rawObject: T | {};
    public formBuilder: FormBuilder;
    public formGroup: FormGroup;

    protected constructor(public injector: Injector, public options: BaseDetailComponentOptions) {
        super(injector, options);
        this.activatedRoute = injector.get(ActivatedRoute);
        this.formBuilder = injector.get(FormBuilder);
        this.pk = options.pk ?? "id";
    }


    ngOnInit(callback?: () => void): void {
        super.ngOnInit();
        this.createFormGroup();

        if (this.options.retrieveOnInit) {
            this.retrieve(callback);
        } else {
            handler(EVENT.RETRIEVE, callback);
        }
    }


    public abstract createFormGroup(): void;

    get f() {
        return this.formGroup.controls;
    }

    // Convenience getter for easy access to form fields values
    get v() {
        return this.formGroup.value;
    }

    // Convenience getter for easy access to form fields raw values
    get rv() {
        return this.formGroup.getRawValue();
    }


    public saveOrUpdate(callback?: (event: number) => void): void {
        this._saveOrUpdate(false, false, callback);
    }

    // Save or update object and return to save mode
    public saveOrUpdatePlus(callback?: (event: number) => void, skippCreateMode?: boolean): void {
        this._saveOrUpdate(false, true, callback, skippCreateMode);
    }

    // Save or update object as multipart/form-data
    public saveOrUpdateFormData(callback?: (event: number) => void): void {
        this._saveOrUpdate(true, false, callback);
    }

    // Save or update object as multipart/form-data and return to save mode
    public saveOrUpdateFormDataPlus(callback?: (event: number) => void): void {
        this._saveOrUpdate(true, true, callback);
    }

    private _saveOrUpdate(isFormData: boolean, isPlus: boolean, callback?: (event: number) => void, skippCreateMode?: boolean): void {

        // Get data to save or update
        let data;
        if (isFormData) {
            data = new FormData();
            Object.keys(this.rv).forEach(key => {
                const value = this.rv[key];
                data.append(key, value === null || value === undefined ? "" : value);
            });
        } else {
            Object.assign(this.object, this.rv);
            data = this.object;
        }

        // Save or update according ID
        if (this.object[this.pk]) {
            this.service.update(this.object[this.pk], data)
                .pipe(take(1))
                .subscribe(response => {
                        this.toast.success("success-title", "updated-successfully");
                        this.rawObject = response;
                        this._response(isPlus ? null : response, EVENT.UPDATE, callback, skippCreateMode);
                    }
                );
        } else {
            this.service.save(data)
                .pipe(take(1))
                .subscribe(response => {
                        this.toast.success("success-title", "saved-successfully");
                        this.rawObject = response;
                        this._response(isPlus ? null : response, EVENT.SAVE, callback, skippCreateMode);
                    }
                );
        }
    }

    public retrieveParam(name: string): Observable<number | string> {
        return this.activatedRoute.params.pipe(
            take(1),
            map((params: Params) => {
                const value = params[name];
                return value ? value : null;
            })
        );
    }

    public retrieve(callback?: () => void): void {
        this.service.clearParameter();
        // Add parameters to filter retrieve
        if (this.options.paramsOnInit) {
            const parameters = this.options.paramsOnInit;
            Object.keys(parameters).forEach(t => this.service.addParameter(t, parameters[t]));
        }
        // Retrieve object
        this.beforeRetrieve().pipe(
            take(1),
            takeWhile(id => {
                if (!!id) {
                    return true;
                }
                handler(EVENT.RETRIEVE, callback);
                return false;
            }),
            switchMap(id => {
                this.object[this.pk] = id;
                return this.service.getById(id, this.options.retrieveRoute);
            })
        ).subscribe(response => {
            this.rawObject = response;
            this._response(response, EVENT.RETRIEVE, callback);
        });
    }

    public beforeRetrieve(): Observable<number | string> {

        // by default the id will be captured by active route parameters
        return this.activatedRoute.params.pipe(
            take(1),
            map((params: Params) => {
                const id = params[this.options.retrieveIdRoute || "action"];
                return id && id !== "create" ? id : null;
            })
        );
    }

    private _response(response: any, event: number, callback?: (event: number) => void, skippCreateMode?: boolean) {
        if (this.options.noResponse || !([EVENT.RETRIEVE, EVENT.SAVE, EVENT.UPDATE].includes(event))) {
            handler(event, callback);
            return;
        }
        if (response) {
            this.object = response;
            if (this.formGroup) {
                this.formGroup.reset(this.object);
            }

            if (this.options.nextRouteUpdate) {
                if (event === EVENT.SAVE) {
                    this._changeToUpdateMode();
                } else if (event === EVENT.UPDATE) {
                    this.goToPage(this.options.nextRouteUpdate);
                }
            } else if (this.options.nextRoute) {
                if (event === EVENT.SAVE || event === EVENT.UPDATE) {
                    this.goToPage(this.options.nextRoute);
                }
            }
        } else {
            this.object = {};
            this.createFormGroup();
            this.requestFocus();
            if (!skippCreateMode) {
                this._changeToCreateMode();
            }
        }
        handler(event, callback);
    }


    private _changeToCreateMode() {
        const route = this._getPathRoute(this.router.routerState.snapshot.root)
            .map(path => path.replace(":action", "create"));
        this.router.navigate([route.join("/")], {queryParamsHandling: "preserve"}).then();
    }

    private _changeToUpdateMode() {
        const route = this._getPathRoute(this.router.routerState.snapshot.root)
            .map(path => path.replace(":action", this.object[this.pk]));
        this.router.navigate([route.join("/")], {queryParamsHandling: "preserve"}).then();
    }

    private _getPathRoute(route: ActivatedRouteSnapshot) {
        let array = [];
        if (route.routeConfig && route.routeConfig.path !== "") {
            array.push(route.routeConfig.path);
        }
        if (route.firstChild) {
            array = array.concat(this._getPathRoute(route.firstChild));
        }
        return array;
    }

    public enableControls(...fields: string[]): void {
        fields.forEach(key => {
            this.f[key].enable();
        });
    }

    public disableControls(...fields: string[]): void {
        fields.forEach(key => {
            this.f[key].disable();
        });
    }

    public resetAndDisableControls(...fields: string[]): void {
        fields.forEach(key => {
            this.f[key].reset();
            this.f[key].setErrors(null);
            this.f[key].disable();
        });
    }


    get isFormValidSave(): boolean {
        return this.formGroup.valid && this.formGroup.dirty;
    }

    get isFormValidSaveMessage(): string {
        if (!this.formGroup.valid) return this.translate._("fill_mandatory_fields");
        else if (!this.formGroup.dirty) return this.translate._("there_are_no_changes_to_save");
        return "";
    }

    public handleField(
        control: AbstractControl,
        dependentControl: AbstractControl,
        condition: (value: string | number | boolean) => boolean
    ): void {
        control.valueChanges.subscribe((value): void => {
            if (condition(value)) {
                dependentControl.enable();
            } else {
                dependentControl.disable();
                dependentControl.setValue(null);
            }
        });

        if (condition(control.value)) {
            dependentControl.enable();
        } else {
            dependentControl.disable();
            dependentControl.setValue(null);
        }
    }
}
