import {Routes} from "@angular/router";
import {MainComponent} from "./components/default/main/main.component";
import {PageNotfoundComponent} from "./components/default/page-notfound/page-notfound.component";
import {LoginComponent} from "./components/default/login/login.component";
import {CreateUserCandidateComponent} from "./components/default/create-user-candidate/create-user-candidate.component";
import {ProfileBadgeComponent} from "./components/default/profile-badge/profile-badge.component";
import {ForgotPasswordComponent} from "./components/default/forgot-password/forgot-password.component";
import {NewPasswordComponent} from "./components/default/forgot-password/new-password/new-password.component";
import {ExternalVacancyComponent} from "./components/default/external-vacancy/external-vacancy.component";
import {
    ExternalVacanciesItemComponent
} from "./components/default/external-vacancy/external-vacancies-item/external-vacancies-item.component";
import {AppGuard} from "./services/permission.service";

export const ROUTES: Routes = [
    {path: "login", component: LoginComponent,},
    {path: "vacancies", component: ExternalVacancyComponent},
    {path: "vacancies_item/:action", component: ExternalVacanciesItemComponent},
    {path: "create_user_candidate", component: CreateUserCandidateComponent},
    {path: "reset_password", component: ForgotPasswordComponent},
    {path: "new-password", component: NewPasswordComponent},
    {path: "profile_badge/:action", component: ProfileBadgeComponent,},
    {
        path: "", component: MainComponent, children: [{path: "", redirectTo: "account/profile", pathMatch: "full"},
            {
                path: "account",
                loadChildren: () => import("./components/account/account.module").then(m => m.AccountModule),
                canActivate: [AppGuard]
            },
            {
                path: "levelup",
                loadChildren: () => import("./components/levelup/levelup.module").then(m => m.LevelupModule),
                canActivate: [AppGuard],
            },
            {
                path: "settings",
                loadChildren: () => import("./components/settings/setting.module").then(m => m.SettingModule),
                canActivate: [AppGuard],

            },
            {
                path: "purchases",
                loadChildren: () => import("./components/purchases/purchases.module").then(m => m.PurchasesModule),
                canActivate: [AppGuard],

            },
            {
                path: "bit", loadChildren: () => import("./components/bit/bit.module").then(m => m.BitModule),
                canActivate: [AppGuard],

            },
            {
                path: "rh", loadChildren: () => import("./components/rh/rh.module").then(m => m.RhModule),
                canActivate: [AppGuard],

            },
            {
                path: "labs", loadChildren: () => import("./components/labs/labs.module").then(m => m.LabsModule),
                canActivate: [AppGuard],

            },
            {
                path: "basic_registration",
                loadChildren: () => import("./components/basic-registration/basic-registration.module").then(m => m.BasicRegistrationModule),

                canActivate: [AppGuard],

            },
            {
                path: "patrimony",
                loadChildren: () => import("./components/patrimony/patrimony.module").then(m => m.PatrimonyModule),
                canActivate: [AppGuard],

            },
            {path: "**", component: PageNotfoundComponent}]
    }
];
