import {AuthService} from "../services/auth.service";
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {BehaviorSubject, Observable, throwError} from "rxjs";
import {catchError, filter, switchMap, take} from "rxjs/operators";
import {URLS} from "../app/app.urls";
import {ToastrService} from "ngx-toastr";
import {TranslateService} from "../services/translate.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    private isRefreshing = false;
    private refreshTokenSubject: BehaviorSubject<{} | null> = new BehaviorSubject<{} | null>(null);

    constructor(private authService: AuthService,
                private translateService: TranslateService,
                public toast: ToastrService) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token = localStorage.getItem("access_token");
        const conventionalPayloadToken = {
            "access": token
        };

        // Inserir o token na requisição inicial se disponível
        if (token) {
            request = this.addTokenHeader(request, conventionalPayloadToken);
        }

        return next.handle(request).pipe(
            catchError((error: HttpErrorResponse) => {
                if (error.status === 401 && !request.url.endsWith(URLS.REFRESH_TOKEN)) {
                    // Tratar erros de não autorizado e tentar atualizar o token
                    return this.handle401Error(request, next);
                }
                this.handleError(error);
            })
        );
    }

    private handle401Error(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!this.isRefreshing) {
            this.isRefreshing = true;
            this.refreshTokenSubject.next(null);

            return this.authService.refreshToken().pipe(
                switchMap((newToken: {}) => {
                    this.isRefreshing = false;
                    this.authService.setToken(newToken);
                    this.refreshTokenSubject.next(newToken);
                    return next.handle(this.addTokenHeader(request, newToken));
                }),
                catchError((err) => {
                    this.isRefreshing = false;
                    this.refreshTokenSubject.next(null);
                    this.authService.logout(false, true);
                    return throwError(err);
                })
            );
        } else {
            // Aguardar a atualização do token estar completa
            return this.refreshTokenSubject.pipe(
                filter(token => token !== null),
                take(1),
                switchMap(token => {
                    return next.handle(this.addTokenHeader(request, token));
                })
            );
        }
    }

    private addTokenHeader(request: HttpRequest<any>, token: {}): HttpRequest<any> {
        return request.clone({
            headers: request.headers.set("Authorization", `Bearer ${token["access"]}`).set("Accept-Language", this.translateService.currentLang)
        });
    }

    private showErrors(title: string, value: any): void {
        Object.keys(value).forEach((key: any) => {
            if (value[key] instanceof Array) {
                this.toast.error(value[key][0], title);
            } else {
                this.toast.error(value[key], title);
            }
        });
    }

    // Function to capture errors
    private static captureError(value: any): any[] {
        if (value instanceof Array) {
            return value;
        } else if (AuthInterceptor.isJson(value)) {
            return [value];
        }
        return [{detail: value}];
    }

    private static isJson(item: any) {
        item = typeof item !== "string" ? JSON.stringify(item) : item;
        try {
            item = JSON.parse(item);
        } catch (e) {
            return false;
        }
        return typeof item === "object" && item !== null;
    }

    private handleError(err: HttpErrorResponse): void {
        const title = this.translateService._(`http-error-${err.status}`);

        if (err.status === 0) {
            this.toast.error(title, "unknown-error");
            return;
        }

        const errors = AuthInterceptor.captureError(err.error);
        errors.forEach(t => {
            if (t instanceof Blob) {
                const reader = new FileReader();
                reader.addEventListener("loadend", () => {
                    this.showErrors(title, JSON.parse(reader.result.toString()));
                });
                reader.readAsText(t);
            } else {
                this.showErrors(title, t);
            }
        });
    }


}
