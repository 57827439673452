<mat-toolbar class="mat-dialog-header">
    <span fpfLayout fpfFlex>{{data?.title | translate}}</span>
    <button mat-icon-button [mat-dialog-close]="false">
        <mat-icon>close</mat-icon>
    </button>
</mat-toolbar>

<mat-dialog-content fpfLayout="column" fpfLayoutAlign="start center" fpfLayoutGap="1">
    <span>{{data?.message | translate}}</span>
    <strong>{{data?.description | translate}}</strong>
</mat-dialog-content>

<mat-dialog-actions fpfFlex fpfFlexLayout="row" fpfLayoutAlign="between center">
    <button mat-stroked-button color="warn" [mat-dialog-close]="false" tabindex="1" class="ml-2 mb-2">
        {{label_button_no | translate}}
    </button>
    <button mat-raised-button color="{{default_color}}" [mat-dialog-close]="true" tabindex="2" class="mr-2 mb-2">
        {{label_button_yes | translate}}
    </button>
</mat-dialog-actions>
