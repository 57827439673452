@if (avatar) {
    <img [ngClass]="{
    'avatar': otherImages(),
    'avatar-profile': isProfile,
    'feedback': isFeedbackActive,
    'preview': isPreview,
    'to-selection': isToSelection
    }" listImage [avatar]="avatar" [changeEvent]="changeEvent" alt="">
} @else {
    <img ngSrc="assets/images/bit-perfil.svg" [ngClass]="{
    'avatar': otherImages(),
    'avatar-profile': isProfile,
    'feedback': isFeedbackActive,
    'preview': isPreview,
    'to-selection': isToSelection
    }" width="100" height="100" alt="{{ nickname }}">
}
