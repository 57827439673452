import {inject, Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot} from "@angular/router";
import {AuthService} from "./auth.service";

@Injectable({
    providedIn: "root"
})
class PermissionsService {

    public readonly excludedRouters = [
        "/account/profile",
        "/account/profile/edit",
        "/account/curriculum/logged",
        "/account/curriculum",
        "/login",
        "/mapping/user_mapping/result/",
        "/levelup/basic/all-individual-development-program/",
    ];

    constructor(private router: Router, private authService: AuthService) {
    }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        return this.isLogged() && this.hasPermission(state);
    }

    private isLogged(): boolean {
        if (this.authService.isLoggedIn()) {
            return true;
        }
        // not logged in so redirect to login page with the current url
        this.authService.logout(false, true);
        return false;
    }

    private hasPermission(state: RouterStateSnapshot): boolean {
        if (this.authService.user.is_superuser) {
            return true;
        }


        if (this.excludedRouters.some(item => state.url.includes(item))) {
            return true;
        }

        const menus = localStorage.getItem("menus");
        if (menus) {
            const decodeMenu = JSON.parse(menus);

            const exists = decodeMenu.flatMap(menu => menu.items).some(item => state.url.includes(item.route));

            if (!exists) {
                return false;
            }

        }
        return true;
    }
}

export const AppGuard: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean => {
    return inject(PermissionsService).canActivate(next, state);
};




